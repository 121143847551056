<template>
  <div class="page-cu-container">
    <div class="page-cu-top">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>
        <!-- <a-form-item>
          <a-select
            v-model="page.type"
            placeholder="请选择类型"
            style="width: 200px"
          >
            <a-select-option value="1">通知</a-select-option>
            <a-select-option value="2">公告</a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button type="default" @click="addAction" icon="plus-circle"
            >新增</a-button
          >
        </a-form-item>
      </a-form>
    </div>
    <div class="page-cu-main">
      <vuescroll>
        <a-table
          :columns="columns"
          :data-source="list"
          rowKey="id"
          bordered
          :pagination="false"
        >
          <template slot="state" slot-scope="text, record">
            <a-tag color="green" v-if="text == '1'">正常</a-tag>
            <a-tag color="orange" v-if="text == '2'">关闭</a-tag>
          </template>
          <template slot="type" slot-scope="text, record">
            <!-- <a-tag color="green" v-if="text == '1'">是</a-tag>
             <a-tag color="orange" v-if="text == '2'">否</a-tag> -->
            <span v-if="text == '1'">普通</span>
            <span v-if="text == '2'">外链</span>
          </template>

          <template slot="action" slot-scope="text, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                更多操作 <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item @click="() => editAction(record['id'])">
                  <a-button type="link" size="small" icon="edit">编辑</a-button>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item @click="() => delConfirm(record['id'])">
                  <a-button type="link" size="small" icon="delete"
                    >删除</a-button
                  >
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item
                  v-if="record['state'] == '1'"
                  @click="
                    () => enabledState(record['id'], 2, '你确定要禁用吗？')
                  "
                >
                  <a-button type="link" size="small" icon="minus-circle"
                    >禁用</a-button
                  >
                </a-menu-item>
                <a-menu-item
                  v-if="record['state'] == '2'"
                  @click="enabledState(record['id'], 1, '你确定要启用吗？')"
                >
                  <a-button type="link" size="small" icon="check-circle"
                    >启用</a-button
                  >
                </a-menu-item>
                <a-menu-divider />
              </a-menu>
            </a-dropdown>
          </template>
        </a-table>
        <div class="page-cu-pagination">
          <a-pagination
          :show-total="(total, range) => `总数：${total} 条`"
            :page-size.sync="page.pageSize"
            :total="page.totalRow"
            v-model="page.start"
            @change="changePage"
          />
        </div>
      </vuescroll>
    </div>

    <a-modal
      title="新增"
      :visible="actionVisible"
      @ok="actionData"
      width="800px"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancel"
      :confirmLoading="$store.state.requestLoading"
    >
      <div style="max-height: 700px; overflow-y: auto">
        <a-form-model
          ref="formModel"
          :rules="formModelRules"
          :model="formModel"
          layout="vertical"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
        >
          <a-form-model-item label="公告名称" prop="title">
            <a-input
              v-model="formModel.title"
              placeholder="请输入公告名称"
            ></a-input>
          </a-form-model-item>
          <!-- <a-form-model-item label="公告类型" prop="type">
            <a-radio-group v-model="formModel.type">
              <a-radio value="1"> 普通 </a-radio>
              <a-radio value="2"> 外链 </a-radio>
            </a-radio-group>
          </a-form-model-item>
           <a-form-model-item  v-if="formModel.type == '2'" label="外部链接" prop="link_url">
            <a-input
              v-model="formModel.link_url"
              placeholder="外部链接"
            ></a-input>
            <div class="tips"><a-icon style="color:red;margin-right:10px;" type="question-circle" />如需要自己编辑,外部链接留空即可</div>
          </a-form-model-item> -->
          
          <div>
            <a-form-model-item label="公告内容" prop="content">
              <custom-editor v-bind:content.sync="formModel.content" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-input
                v-model="formModel.remark"
                type="textarea"
                row="2"
                placeholder="请输入备注"
              ></a-input>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      isAdd: true, // TODO: 是否是新增操作
      formModel: {
        id: "",
        title: "",
        type: "1",
        content: "",
        remark: ""
      },
      formModelRules: {
        title: [
          { required: true, message: "请输入公告名称", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择公告类型", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入公告内容", trigger: "change" },
        ],
        link_url: [
          { required: true, message: "请输入外链地址", trigger: "change" },
        ],
      },

      columns: [
        {
          title: "公告名称",
          dataIndex: "title",
        },
        {
          title: "公告类型",
          dataIndex: "type",
          scopedSlots: { customRender: "type" },
        },
        // {
        //   title: "公告内容",
        //   dataIndex: "content",
        // },
        {
          title: "更新时间",
          dataIndex: "update_time",
        },
        {
          title: "状态",
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
        },
        {
          title: "外链",
          dataIndex: "remark",
        },
        {
          title: "操作",
          width: 150,
          scopedSlots: { customRender: "action" },
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        key: "",
        type: undefined,
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    /**
     * 添加，更新
     *
     */
    actionData() {
      this.$refs.formModel.validate(async (valid) => {
        if (valid) {
          let res = null;
          console.log(this.formModel);
          if (this.formModel["id"]) {
            res = await Api.Update(this.formModel);
          } else {
            res = await Api.Save(this.formModel);
          }

          if (res && res["code"] == "0") {
            this.$message.success(res.message);
            this.actionVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        }
      });
    },

    /*
     * 表单重置
     **/
    resetFormModel() {
      for (let key in this.formModel) {
        this.formModel[key] = "";
      }
    },
    // 菜单选择
    handlerCheck(n, e) {
      this.checkedKeys = { checked: n, halfChecked: e.halfCheckedKeys };
    },
    // 打开添加公告窗
    addAction() {
      this.isAdd = true;
      this.resetFormModel();
      this.actionVisible = true;
    },

    // 编辑
    async editAction(id) {
      this.isAdd = false;
      this.resetFormModel();
      let params = {
        id: id,
      };
      let res = await Api.Detail(params);
      Object.keys(this.formModel).forEach((key) => {
        this.formModel[key] = res.target[key];
      });
      this.actionVisible = true;
    },

    // 关闭窗口
    cancel() {
      this.actionVisible = false;
    },

    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    // 启用 / 禁用
    enabledState(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            state: state,
          };
          let res = await Api.AdminEnabled(params);
          if (res && res["code"] == "0") {
            that.getList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style scoped>
</style>

